body {
  background-color: #161B40;
  font-family: 'Quanta Grotesk';
  letter-spacing: 10%;

  h1, h2, h3, h4, p {
    color: #f8bd7f;
  }
}

#logo {
  position: fixed;
  top: 0;
  text-decoration: none;
  color: #f8bd7f;
  letter-spacing: 10%;
  margin: 1.5%;
  font-size: 35px;
  font-family: 'Anurati';
}

.greeting {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  
  padding-left: 5%;

  h1 {
    margin-top: 0%;
  }
}

#hi {
  margin-bottom: 0%;
}

.skills {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-transform: uppercase;
  letter-spacing: 10%;
  margin-bottom: 10%;
}

.skillBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h3 {
    text-decoration: underline;
  }
}

.skillIcons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
  flex-wrap: wrap;
  margin-top: 5%;

  svg {
    flex-basis: 30%;
    margin-bottom: 3%;
  }
}

.description {
  margin-bottom: 4%;
  font-size: 20px;
  display: flex;
  flex-wrap: wrap;
}

.projects {
  display: flex;
  margin-bottom: 2%;

  a {
    display: flex;
    justify-content: space-evenly;
    width: 50%;
  }

  img {
    width: 50%;
  }
}

.projects a {
  display: flex;
}

.centeredText, .description {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.links {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 15%;
  margin: 1% auto;
}

#copyright {
  display: flex;
  justify-content: center;
}

svg {
  color: white;

  :hover {
    color:#f8bd7f;
  }
}

/* Project pages */

.projectPage {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  .leftPart {
    display: flex;
    justify-content: center;
  }

  img {
    width: 80%;
  }

  .rightPart {
    display: flex;
    flex-direction: column;
  }

  a:link {
    color: #f8bd7f;
    text-decoration: underline;
  }

}

@font-face {
  font-family: 'Quanta Grotesk';
  src: local('Quanta Grotesk'), url(./fonts/QuantaGrotesk/QuantaGroteskPro-Regular.otf) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Anurati';
  src: local('Anurati'), url(./fonts/Anurati/Anurati-Regular.otf) format('opentype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}